
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400&display=swap');
body, h1, h2, h3, h4, h5, h6, a, p ,div,label{font-family: 'Lato', sans-serif !important;}
.payment-section{border-radius: 8px;border: 1px solid #A0A1A2;box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);width: 488px;margin: 30px auto;background: #fff;padding: 16px;}
.logo-flex{display: flex;align-items: center;gap: 6px;justify-content: center;}
.logo-flex .logo-style{width: 20px;height: auto;}
.logo-flex h3{font-size: 26px;font-weight: 600;color: #000;margin-bottom: 0;margin-top: 0;}
.scanner-style img{width: 246px;}
.scanner-style{text-align: center;}
.payment-section input{border: 1px solid #9FA2B4;border-radius: 5px;height: 46px;width: 95%;outline: none; padding: 0 12px;}
.payment-section .p-relative{position: relative;}
.payment-section label{position: absolute;top: -10px;left: 10px;z-index: 1;height: auto;line-height: normal;background: #fff;padding: 0 4px;font-size: 14px;font-weight: 400;color: #000;}
.payment-section label{line-height: normal;height: auto;color: #000 !important;display: inline-block;}
.payment-section input:-webkit-autofill, .payment-section input:-webkit-autofill:hover, .payment-section input:-webkit-autofill:focus, .payment-section input:-webkit-autofill:active{-webkit-box-shadow:0 0 0 30px rgb(255 255 255) inset !important;color: #000;-webkit-text-fill-color:#000;}
.payment-section h4{color: #000;font-size: 16px;font-weight: 500;margin-bottom: 16px;margin-top: 10px;}
.payment-section .metamask-connect{background: #EEF0F4;padding: 9px;border-radius: 8px;display: flex;justify-content: space-between;align-items: center;cursor: pointer;height: auto;width: 100%;}
.mb-18{margin-bottom: 18px !important;}
.payment-section .address-text{display: flex;align-items: center;justify-content: center;gap: 10px;font-size: 14px;font-weight: 500;color: #000;margin-top: 10px;}
.payment-section .balance-text{font-size: 14px;font-weight: 500;color: #000;text-align: center;}
.payment-section .text-gray{color: rgba(0, 0, 0, 0.50);}
.payment-section .mx-6{margin: 6px 0;}
.payment-section .submit-btn{border-radius: 6px;border: 1px solid #4096FF;background: #4096FF;color: #fff;text-align: center;height: 41px;width: 100%;font-weight: 500;}
.filters-wrap{flex-wrap: wrap;row-gap: 16px;}
.commisions-terms{color: rgba(255, 255, 255, 0.80);font-size: 14px;}
.cust-qr{text-align: center;}
.d-flex{display: flex;}
.align-items-center{align-items: center;}
.gap-8{gap: 8px;}
.mb-22{margin-bottom: 22px !important;}
.hr-line{display: flex;align-items: center;gap: 10px;}
.hr-line hr{flex: 1;}
.hr-line p{margin: 0;}
.mb-10{margin-bottom: 10px;}
.required{color: #ff5747;font-size: 14px;font-weight: 500;}
.text-red{color: #ff1414;}
.payment-section .submit-btn:hover{background: #2e6fbe !important;color: #fff !important;}
.text-center{text-align: center;}
.check-img{width: 100px;}
.declaration{font-size: 18px;font-weight: 500;}
.content-center{height: 80vh;display: flex;align-items: center;justify-content: center;}
.expired-content-center{display: flex;align-items: center;justify-content: center;height: 100vh;background-image: url(../images/expired_bg.svg);background-repeat: no-repeat;background-size: cover;}
:where(.css-dev-only-do-not-override-1pg9a38).ant-spin .ant-spin-dot{height: 32px;width: 32px;}
:where(.css-dev-only-do-not-override-1pg9a38).ant-spin .ant-spin-dot-item{height: 16px;width: 16px;}
.alert-flex{margin: 16px auto 0;}
.alert-flex .ant-alert-description,.anticon-close{color: #ff5747 !important;}
.alert-flex .anticon-close{transform: scale(1.5);}
.ant-alert-close-icon{margin-top: 6px;}
.expired-img{height: 75px;}
.declaration-heading{font-size: 22px;font-weight: 700;color: #000;margin-bottom: 8px;}
.link-declaration{font-size: 20px;font-weight: 400;color: #989898;margin-top: 0;}
.p-45{padding: 45px;width: 400px;}
.expired-content-center .payment-section{background: transparent;}
@media (max-width: 767px){
    .p-45{padding: 16px;}
    .expired-img{height: 100%;}
    .payment-section{width: 82%;}
    .content-center{padding: 16px;}
    .payment-section input{width: 92%;}
}